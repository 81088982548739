/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import ErrorsPage from "../pages/errors/ErrorsPage";
import SuccessPage from "../pages/success/SuccessPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import Home from "../pages/auth/Home";
import About from "../pages/auth/About";
import Contact from "../pages/auth/Contact";
import Impact from "../pages/auth/Impact";
import Results from "../pages/auth/Results";
import Consortium from "../pages/auth/Consortium";

import News from "../pages/auth/News";
import AuthPage3 from "../pages/auth/AuthPage3";

export const Routes = withRouter(({ history }) => {
    const lastLocation = useLastLocation();
    routerHelpers.saveLastLocation(lastLocation);
    const { isAuthorized, menuConfig, userLastLocation } = useSelector(
        ({ auth, urls, builder: { menuConfig } }) => ({
            menuConfig,
            isAuthorized: auth.user != null && auth.user!==""&& auth.user!==undefined,
            userLastLocation: routerHelpers.getLastLocation()
        }),
        shallowEqual
    );

    //console.log(isAuthorized)

    return (
        /* Create `LayoutContext` from current `history` and `menuConfig`. */
        <LayoutContextProvider history={history} menuConfig={menuConfig}>
            <Switch>


                    <Switch>
                        {
                        /* Redirect from root URL to /dashboard. */
                        <Redirect exact from="/" to="/home" />

                         }
                        <Route path="/home" component={Home} />
                        <Route path="/about" component={About} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/impact" component={Impact} />
                        <Route path="/results" component={Results} />
                        <Route path="/consortium" component={Consortium} />
                        <Route path="/news" component={News} />



                    </Switch>



                <Route path="/error" component={ErrorsPage} />
                <Route path="/success" component={SuccessPage} />




            </Switch>
        </LayoutContextProvider>
    );
});
