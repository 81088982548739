import React, {useMemo, useState, useEffect} from "react";
import {toAbsoluteUrl} from "../../../_metronic";
import {NavLink, Link} from "react-router-dom";
import "../../../_metronic/vendors/bootstrap/bootstrap.min.css";
import "../../../_metronic/vendors/themify-icons/themify-icons.css";
import "../../../_metronic/vendors/linericon/style.css";
import "../../../_metronic/vendors/owl-carousel/owl.theme.default.min.css";
import "../../../_metronic/vendors/owl-carousel/owl.carousel.min.css";
import "../../../_metronic/vendors/style.css";
import "../../../_metronic/_assets/sass/custom/styleAut2.css";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import InfoIcon from "@material-ui/icons/Info";
import GetApp from "@material-ui/icons/GetApp";


const styles = theme => ({
    textField: {
        height: 50,
        width: 359,
        borderRadius: 2,
        borderColor: '#fff',
        backgroundColor: '#FFFFFF'
    },
    input: {
        height: 50,
        width: 359,
        borderRadius: 2,
        borderColor: '#fff',
        backgroundColor: '#FFFFFF'
    }
});

const Navigation = styled.header`



  .gray {
  }
  a {
    opacity: 1;
    transition: all 0.6s;
    font-size: 1em;
  }

  .fa-bars {
    display: none;
    font-size: 2rem;
  }
  nav {
    ul {
      display: flex;
      justify-content: space-between;
    }
    li {
      margin: 0 15px;
      justify-content: space-between;
      font-size: 1em;
    }
    a {
      font-size: 1em;
      text-decoration: none;
      .active {
      }
    }
    a.active {
    }
  }

  @media only screen and (max-width: 800px) {
    padding: 0px;
    .logo {
      padding-left: 15px;
      padding-top: 0px !important;
    }
  }
  @media only screen and (max-width: 600px) {
    height: auto;
    min-height: 50px;
    display: block;
    position: relative;
    .logo {
      width: 100%;
      display: block;
      padding-top: 20px;
      margin: 0px;
      margin-left: -5px;
      a {
        padding: 20px 0px;
      }
    }
    .fa-bars {
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    ul.collapsed {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: wrap;

      overflow: hidden;
      max-height: 0;
      -moz-transition-duration: 0.4s;
      -webkit-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      transition-duration: 0.4s;
      -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

      &.is-expanded {
        overflow: hidden;
        max-height: 500px; /* approximate max height */
        -moz-transition-duration: 0.4s;
        -webkit-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s;
        -moz-transition-timing-function: ease-in;
        -webkit-transition-timing-function: ease-in;
        -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
      }
      li {
        padding: 15px 10px;
        padding: 15px 10px;
        margin: 0px 0px;
        width: 100%;
        
      }
    }
  }
`;

const {SearchBar} = Search;

function priceFormatter(column, colIndex) {
    return (
        <span style={{color:'#1B2848', fontSize:16, fontFamily:'Roboto', padding:'20px', borderRightWidth:'0px'}}> { column.text } </span>

    );
}

const columns = [{
    dataField: 'title',
    text: 'Title',
    headerFormatter: priceFormatter,
    style: (cell, row, rowIndex, colIndex) => {
        if (rowIndex % 2 === 0) {
            return {
                fontSize:16,
                fontFamily:'Roboto',
                color:'#1B2848',
                padding:'20px',
                border:'none',
                backgroundColor: '#F4FAFF'
            };
        }
        return {
            fontSize:16,
            fontFamily:'Roboto',
            color:'#1B2848',
            padding:'20px',
            border:'none',
            backgroundColor: 'white'
        };
    }

}, {
    dataField: 'workPackage',
    text: 'Work Package',

    style: (cell, row, rowIndex, colIndex) => {
        if (rowIndex % 2 === 0) {
            return {
                fontSize:16,
                padding:'20px',
                color:'#0A8AFF',
                border:'none',
                backgroundColor: '#F4FAFF'
            };
        }
        return {
            fontSize:16,
            padding:'20px',
            color:'#0A8AFF',
            border:'none',
            backgroundColor: 'white'
        };
    }
}, {
    dataField: 'date',
    text: 'Publication Date',
    style: (cell, row, rowIndex, colIndex) => {
        if (rowIndex % 2 === 0) {
            return {
                fontSize:16,
                padding:'20px',
                border:'none',
                backgroundColor: '#F4FAFF'
            };
        }
        return {
            fontSize:16,
            padding:'20px',
            border:'none',
            backgroundColor: 'white'
        };
    },
    sort: true
}, {
    dataField: 'download',
    text: 'Download',
    style: (cell, row, rowIndex, colIndex) => {
        if (rowIndex % 2 === 0) {
            return {
                padding:'20px',
                border:'none',
                backgroundColor: '#F4FAFF'
            };
        }
        return {
            padding:'20px',
            border:'none',
            backgroundColor: 'white'
        };
    },
    align: (cell, row, rowIndex, colIndex) => {
        return 'center';
    }
}];

const products = [{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage: 'Mavennet',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage: 'Ryerson University',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage:'Mavennet',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage: 'Ryerson University',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage:'Mavennet',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
}, {
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage: 'Ryerson University',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage: 'Ryerson University',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage:'Mavennet',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage: 'Ryerson University',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage:'Mavennet',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
}, {
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage: 'Ryerson University',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage: 'Ryerson University',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage:'Mavennet',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage: 'Ryerson University',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage:'Mavennet',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
}, {
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage: 'Ryerson University',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage: 'Ryerson University',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage:'Mavennet',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage: 'Ryerson University',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage:'Mavennet',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
}, {
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage: 'Ryerson University',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage: 'Ryerson University',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage:'Mavennet',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage: 'Ryerson University',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage:'Mavennet',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
}, {
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage: 'Ryerson University',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage: 'Ryerson University',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage:'Mavennet',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage: 'Ryerson University',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
},{
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage:'Mavennet',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
}, {
    title: 'Result title Consectetuer adipiscing elit, sed diam nonummy nibh',
    workPackage: 'Ryerson University',
    date: 'July 9,2020',
    download: <GetApp style={{color:'#0A8AFF', fontSize:24}}/>
}, ];
const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
  </span>
);

const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
        text: '5', value: 5
    }, {
        text: '10', value: 10
    }, {
        text: 'All', value: products.length
    }] // A numeric array is also available. the purpose of above example is custom the text
};

export default function Results() {
    const [isExpanded, setExpanded] = useState(false);
    const classes = styles();

    function handleToggle(e) {
        e.preventDefault();

        setExpanded(!isExpanded);

    }





    return (
        <>
            <div className="section anim">
                <header className="header_area">
                    <Navigation>

                        <div className="main_menu">
                            <nav className="navbar navbar-expand-lg navbar-light"
                                 style={{backgroundColor: 'white', height: 75}}>
                                <div className="container box_1620" style={{justifyContent: "flex-start"}}>
                                    <Link to="/home" className="navbar-brand logo_h" style={{width: 202}}>
                                        <img src={`${toAbsoluteUrl("/media/logos/I-DELTA-logo.svg")}`}
                                             height='auto'/>
                                    </Link>
                                    <nav className="nav">
                                        <i
                                            className="fa fa-bars"
                                            aria-hidden="true"
                                            onClick={e => handleToggle(e)}
                                        />
                                    </nav>
                                    <div className=" navbar-collapse offset" id="navbarSupportedContent"
                                         style={{backgroundColor: 'white'}}>
                                        <ul
                                            className={`collapsed ${isExpanded ? "is-expanded" : "nav navbar-nav menu_nav justify-content-end"}`}>
                                            <li className="nav-item">
                                                <Link to="/about" className="nav-link" style={{

                                                    color: '#1B2848',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 16,
                                                    fontWeight: 500,

                                                }}>
                                                    About
                                                </Link>
                                            </li>
                                            <li className="nav-item">

                                                <Link to="/consortium" className="nav-link" style={{

                                                    color: '#1B2848',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 16,
                                                    fontWeight: 500,

                                                }}>
                                                    Consortium
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/impact" className="nav-link" style={{

                                                    color: '#1B2848',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 16,
                                                    fontWeight: 500,

                                                }}>
                                                    Impact
                                                </Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link to="/results" className="nav-link" style={{

                                                    color: '#1B2848',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 16,
                                                    fontWeight: 500,

                                                }}>
                                                    Results
                                                </Link>

                                            </li>
                                            <li className="nav-item">
                                                <Link to="/news" className="nav-link" style={{

                                                    color: '#1B2848',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 16,
                                                    fontWeight: 500,

                                                }}>
                                                    News
                                                </Link>

                                            </li>
                                            <li className="nav-item">
                                                <Link to="/contact" className="nav-link" style={{

                                                    color: '#1B2848',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 16,
                                                    fontWeight: 500,

                                                }}>
                                                    Contact
                                                </Link>

                                            </li>

                                        </ul>


                                    </div>

                                </div>
                            </nav>
                        </div>
                    </Navigation>
                </header>


                <main className="side-main" style={{paddingTop: 80}}>
                    <section className="hero-banner hero-banner-result ">
                        <div className="container">
                            <div className="row">

                                <div className="col-lg-8">
                                    <div className="hero-banner__content">
                                        <h1 style={{
                                            color: '#FFFFFF',
                                            fontFamily: 'Roboto',
                                            fontSize: 48,
                                            fontWeight: 'bold',
                                            letterSpacing: '-0.16px',
                                            textAlign: 'left',
                                            lineHeight: 1.5
                                        }}>
                                            The I-DELTA Results

                                        </h1>
                                        <h3 style={{
                                            color: 'white',
                                            fontFamily: 'Roboto',
                                            fontSize: 24,
                                            fontWeight: 500,
                                            letterSpacing: -0.2,

                                        }}>
                                            Explore the latest results of the I-DELTA project
                                        </h3>
                                    </div>
                                </div>

                                <div className="col-lg-5">
                                    <div className="hero-banner__img">
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="section-margin">
                        <div className="container">

                            <ToolkitProvider
                                keyField="id"
                                data={products}
                                columns={columns}
                                search
                            >
                                {
                                    props => (
                                        <div>
                                            <div className='col-lg-12' style={{display:'flex', marginBottom:30}}>
                                                <div style={{ flex:1}}>
                                                    <h3 style={{
                                                        color: '#1B2848',
                                                        fontFamily: 'Roboto',
                                                        fontSize: 34,
                                                        fontWight: 500,
                                                    }}>Result List</h3>
                                                </div>
                                                <div style={{float:'right', justifyContent:'flex-end', flex:1, display:'flex'}}>
                                                    <SearchBar {...props.searchProps}

                                                               className="custome-search-field"
                                                               style={ { width: '100%', height:50 } }
                                                               delay={ 1000 }
                                                               placeholder="Search the result"/>
                                                </div>
                                            </div>

                                            <BootstrapTable   {...props.baseProps} keyField='id' data={products}
                                                              columns={columns}
                                                              bodyClasses='bodyClass'
                                                              headerClasses="customClass"
                                                              pagination={paginationFactory(options)}/>

                                        </div>
                                    )
                                }
                            </ToolkitProvider>


                        </div>
                    </section>

                </main>

                <footer className="footer-area section-gap">
                    <div className="container" style={{color: "#fff"}}>
                        <div className="col-md-12 row" style={{marginBottom: 50}}>
                            <div className="col-md-6">
                                <div className="navbar-brand logo_h" style={{width: 202}}>
                                    <img src={`${toAbsoluteUrl("/media/logos/I-DELTA-logo.svg")}`}
                                         height='auto'/>

                                </div>

                                <div className='powered-by' style={{
                                    marginTop: 30, float: 'left', marginLeft: 14,
                                    clear: 'left'
                                }}>
                                    Powered by

                                </div>
                                <div style={{
                                    marginTop: 10,
                                    float: 'left',
                                    clear: 'left',
                                    display: 'flex'
                                }}>

                                    <div className="navbar-brand logo_h" style={{width: 202}}>
                                        <a href="https://itea3.org/" target='_blank'>
                                            <img src={`${toAbsoluteUrl("/media/img/home/itea3-logo-footer.png")}`}
                                                 width='100%'
                                                 height='auto'/>
                                        </a>
                                    </div>
                                    <div className="navbar-brand logo_h" >
                                        <a href="https://www.eurekanetwork.org/" target='_blank'>
                                            <img src={`${toAbsoluteUrl("/media/img/home/Euraka-logo-footer.svg")}`}
                                                 width='100%'
                                                 height='auto'/>

                                        </a>
                                    </div>

                                </div>

                            </div>

                            <div className="col-md-6">
                                <Navigation>

                                    <div className="main_menu">
                                        <nav className="navbar navbar-expand-lg navbar-light">
                                            <div className="container box_1620" style={{justifyContent: "flex-start"}}>
                                                <nav className="nav">
                                                    <i
                                                        className="fa fa-bars"
                                                        aria-hidden="true"
                                                        onClick={e => handleToggle(e)}
                                                    />
                                                </nav>
                                                <div>
                                                    <ul
                                                        className='nav navbar-nav menu_nav ' style={{marginLeft: 10}}>
                                                        <li className="nav-item">
                                                            <Link to="/about" className="nav-link" style={{

                                                                color: '#1B2848',
                                                                fontFamily: 'Roboto',
                                                                fontSize: 16,
                                                                fontWeight: 500,

                                                            }}>
                                                                About
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">

                                                            <Link to="/consortium" className="nav-link" style={{

                                                                color: '#1B2848',
                                                                fontFamily: 'Roboto',
                                                                fontSize: 16,
                                                                fontWeight: 500,

                                                            }}>
                                                                Consortium
                                                            </Link>
                                                        </li>

                                                        <li className="nav-item">
                                                            <Link to="/results" className="nav-link" style={{

                                                                color: '#1B2848',
                                                                fontFamily: 'Roboto',
                                                                fontSize: 16,
                                                                fontWeight: 500,

                                                            }}>
                                                                Results
                                                            </Link>

                                                        </li>
                                                        <li className="nav-item">
                                                            <Link to="/news" className="nav-link" style={{

                                                                color: '#1B2848',
                                                                fontFamily: 'Roboto',
                                                                fontSize: 16,
                                                                fontWeight: 500,

                                                            }}>
                                                                News
                                                            </Link>

                                                        </li>
                                                        <li className="nav-item">
                                                            <Link to="/contact" className="nav-link" style={{

                                                                color: '#1B2848',
                                                                fontFamily: 'Roboto',
                                                                fontSize: 16,
                                                                fontWeight: 500,

                                                            }}>
                                                                Contact
                                                            </Link>

                                                        </li>

                                                    </ul>


                                                </div>

                                            </div>
                                        </nav>
                                    </div>
                                </Navigation>
                                <div className="navbar-brand logo_h"
                                     style={{float: 'right', marginTop: 10, display: 'flex'}}>
                                    <a href="https://twitter.com/idelta2020" target='_blank' style={{width:26, height:26,marginRight: 20}}>
                                        <img src={`${toAbsoluteUrl("/media/img/home/twitter-icon.svg")}`}
                                             width='100%'
                                             height='auto'/>
                                    </a>
                                    <a target='_blank' style={{width:26, height:26,marginRight: 20}}>
                                        <img src={`${toAbsoluteUrl("/media/img/home/linkedin-icon.svg")}`}
                                             width='100%'
                                             height='auto'/>
                                    </a>
                                    <a target='_blank' style={{width:26, height:26}}>
                                        <img src={`${toAbsoluteUrl("/media/img/home/medium-icon.svg")}`}
                                             width='100%'
                                             height='auto' />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 "

                             style={{
                                 marginTop: 20
                             }}>
                            <div className="col-md-6" style={{
                                marginLeft: 14,
                                color: '#1B2848',
                                fontFamily: 'Roboto',
                                fontSize: 12,
                                display: 'flex'
                            }}>
                                Copyright ©2020 I-DELTA. All rights reserved.

                            </div>

                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}
