import React, { useMemo, useState, useEffect } from "react";
import { toAbsoluteUrl } from "../../../_metronic";
import { NavLink, Link } from "react-router-dom";
import "../../../_metronic/vendors/bootstrap/bootstrap.min.css";
import "../../../_metronic/vendors/themify-icons/themify-icons.css";
import "../../../_metronic/vendors/linericon/style.css";
import "../../../_metronic/vendors/owl-carousel/owl.theme.default.min.css";
import "../../../_metronic/vendors/owl-carousel/owl.carousel.min.css";
import "../../../_metronic/vendors/style.css";
import "../../../_metronic/_assets/sass/custom/styleAut2.css";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
  textField: {
    height: 50,
    width: 359,
    borderRadius: 2,
    borderColor: "#fff",
    backgroundColor: "#FFFFFF"
  },
  input: {
    "&::placeholder": {
      color: "red"
    }
  }
});

const Navigation = styled.header`



  .gray {
  }
  a {
    opacity: 1;
    transition: all 0.6s;
    font-size: 1em;
  }

  .fa-bars {
    display: none;
    font-size: 2rem;
  }
  nav {
    ul {
      display: flex;
      justify-content: space-between;
    }
    li {
      margin: 0 15px;
      justify-content: space-between;
      font-size: 1em;
    }
    a {
      font-size: 1em;
      text-decoration: none;
      .active {
      }
    }
    a.active {
    }
  }

  @media only screen and (max-width: 800px) {
    padding: 0px;
    .logo {
      padding-left: 15px;
      padding-top: 0px !important;
    }
  }
  @media only screen and (max-width: 600px) {
    height: auto;
    min-height: 50px;
    display: block;
    position: relative;
    .logo {
      width: 100%;
      display: block;
      padding-top: 20px;
      margin: 0px;
      margin-left: -5px;
      a {
        padding: 20px 0px;
      }
    }
    .fa-bars {
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    ul.collapsed {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: wrap;

      overflow: hidden;
      max-height: 0;
      -moz-transition-duration: 0.4s;
      -webkit-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      transition-duration: 0.4s;
      -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

      &.is-expanded {
        overflow: hidden;
        max-height: 500px; /* approximate max height */
        -moz-transition-duration: 0.4s;
        -webkit-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s;
        -moz-transition-timing-function: ease-in;
        -webkit-transition-timing-function: ease-in;
        -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
      }
      li {
        padding: 15px 10px;
        padding: 15px 10px;
        margin: 0px 0px;
        width: 100%;
        
      }
    }
  }
`;

export default function Contact() {
  const [isExpanded, setExpanded] = useState(false);
  const classes = styles();

  function handleToggle(e) {
    e.preventDefault();

    setExpanded(!isExpanded);

  }

  return (
    <>
      <div className="section anim">
        <header className="header_area">
          <Navigation>

            <div className="main_menu">
              <nav className="navbar navbar-expand-lg navbar-light"
                   style={{ backgroundColor: "white", height: 75 }}>
                <div className="container box_1620" style={{ justifyContent: "flex-start" }}>
                  <Link to="/home" className="navbar-brand logo_h" style={{ width: 202 }}>
                    <img src={`${toAbsoluteUrl("/media/logos/I-DELTA-logo.svg")}`}
                         height='auto'/>
                  </Link>

                  <nav className="nav">
                    <i
                      className="fa fa-bars"
                      aria-hidden="true"
                      onClick={e => handleToggle(e)}
                    />
                  </nav>
                  <div className=" navbar-collapse offset" id="navbarSupportedContent"
                       style={{ backgroundColor: "white" }}>
                    <ul
                      className={`collapsed ${isExpanded ? "is-expanded" : "nav navbar-nav menu_nav justify-content-end"}`}>
                      <li className="nav-item">
                        <Link to="/about" className="nav-link" style={{

                          color: "#1B2848",
                          fontFamily: "Roboto",
                          fontSize: 16,
                          fontWeight: 500

                        }}>
                          About
                        </Link>
                      </li>
                      <li className="nav-item">

                        <Link to="/consortium" className="nav-link" style={{

                          color: "#1B2848",
                          fontFamily: "Roboto",
                          fontSize: 16,
                          fontWeight: 500

                        }}>
                          Consortium
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/impact" className="nav-link" style={{

                          color: "#1B2848",
                          fontFamily: "Roboto",
                          fontSize: 16,
                          fontWeight: 500

                        }}>
                          Impact
                        </Link>
                      </li>

                      {/*
                                            <li className="nav-item">
                                                <Link to="/results" className="nav-link" style={{

                                                    color: '#1B2848',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 16,
                                                    fontWeight: 500,

                                                }}>
                                                    Results
                                                </Link>

                                            </li>
                                            <li className="nav-item">
                                                <Link to="/news" className="nav-link" style={{

                                                    color: '#1B2848',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 16,
                                                    fontWeight: 500,

                                                }}>
                                                    News
                                                </Link>

                                            </li>
                                            */}
                      <li className="nav-item">
                        <Link to="/contact" className="nav-link" style={{

                          color: "#1B2848",
                          fontFamily: "Roboto",
                          fontSize: 16,
                          fontWeight: 500

                        }}>
                          Contact
                        </Link>

                      </li>

                    </ul>


                  </div>

                </div>
              </nav>
            </div>
          </Navigation>
        </header>


        <main className="side-main" style={{ paddingTop: 80 }}>
          <section className="hero-banner hero-banner-contact mb-30px">
            <div className="container">
              <div className="row">

                <div className="col-lg-8">
                  <div className="hero-banner__content">
                    <h1 style={{
                      color: "#FFFFFF",
                      fontFamily: "Roboto",
                      fontSize: 48,
                      fontWeight: "bold",
                      letterSpacing: "-0.16px",
                      textAlign: "left",
                      lineHeight: 1.5
                    }}>
                      Contact Us

                    </h1>

                    <h3 style={{
                      color: "white",
                      fontFamily: "Roboto",
                      fontSize: 24,
                      fontWeight: 500,
                      letterSpacing: -0.2


                    }}>
                      We’d love to hear from you, let’s get in touch!

                    </h3>


                  </div>
                </div>

                <div className="col-lg-5">
                  <div className="hero-banner__img">
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-padding--small bg-magnolia" style={{ backgroundColor: "#fff" }}>
            <div className="container">
              <div className="col-lg-12">
                <div style={{
                  color: "#1B2848",
                  fontFamily: "Roboto",
                  fontSize: 24,
                  fontWeight: 500
                }}>
                  Send us your questions or comments and our team will contact you shortly.
                </div>

              </div>

              <div className="col-lg-12 ">
                <div className="col-lg-6 ">
                  <div className="col-lg-6 " style={{ marginTop: 40, display: "grid" }}>
                    <input className="form-control" type="text" placeholder="First Name" style={{
                      height: 30,
                      border: "1px solid #787878",
                      borderRadius: 4,
                      fontSize: 16,
                      fontFamily: "Roboto",
                      color: "#787878",
                      backgroundColor: "#FFFFFF",
                      padding: 20
                    }}/>
                  </div>
                  <div className="col-lg-6 " style={{ marginTop: 40, display: "grid" }}>
                    <input className="form-control" type="text" placeholder="Last Name" style={{
                      height: 30,
                      border: "1px solid #787878",
                      borderRadius: 4,
                      fontSize: 16,
                      fontFamily: "Roboto",
                      color: "#787878",
                      backgroundColor: "#FFFFFF",
                      padding: 20
                    }}/>

                  </div>
                  <div className="col-lg-12" style={{ marginTop: 40, display: "grid" }}>
                    <input className="form-control" type="text" placeholder="Email" style={{
                      height: 30,
                      border: "1px solid #787878",
                      borderRadius: 4,
                      fontSize: 16,
                      fontFamily: "Roboto",
                      color: "#787878",
                      backgroundColor: "#FFFFFF",
                      padding: 20
                    }}/>

                  </div>
                  <div className="col-lg-6 " style={{ marginTop: 40, display: "grid" }}>
                    <input className="form-control" type="text" placeholder="Company" style={{
                      height: 30,
                      border: "1px solid #787878",
                      borderRadius: 4,
                      fontSize: 16,
                      fontFamily: "Roboto",
                      color: "#787878",
                      backgroundColor: "#FFFFFF",
                      padding: 20
                    }}/>

                  </div>
                  <div className="col-lg-6 " style={{ marginTop: 40, display: "grid" }}>
                    <input className="form-control" type="text" placeholder="Job Title " style={{
                      height: 30,
                      border: "1px solid #787878",
                      borderRadius: 4,
                      fontSize: 16,
                      fontFamily: "Roboto",
                      color: "#787878",
                      backgroundColor: "#FFFFFF",
                      padding: 20
                    }}/>

                  </div>
                </div>
                <div className="col-lg-6 " style={{ marginTop: 40, display: "grid" }}>
                  <div className="form-group">
                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="8"
                              placeholder="Tell us a bit more about your inquiries " style={{
                      border: "1px solid #787878",
                      borderRadius: 4,
                      fontSize: 16,
                      fontFamily: "Roboto",
                      color: "#787878",
                      backgroundColor: "#FFFFFF"
                    }}>
                    </textarea>
                  </div>
                  <div style={{
                    float: "right",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    display: "flex"
                  }}>

                    <a href="mailto:name@mail.com?subject=ContactUs&body="
                       style={{
                         height: 50,
                         width: 135,
                         borderRadius: 2,
                         backgroundColor: "#0A8AFF",

                         display: "flex",
                         justifyContent: "center",
                         alignItems: "center"
                       }}>
                                        <span style={{
                                          color: "#FFFFFF",
                                          fontFamily: "Barlow",
                                          fontSize: 16,
                                          fontWeight: 500,
                                          letterSpacing: 0,
                                          textAlign: "center"

                                        }}>
                                           Submit
                                        </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <footer className="footer-area section-gap">
          <div className="container" style={{ color: "#fff" }}>
            <div className="col-md-12 row" style={{ marginBottom: 50 }}>
              <div className="col-md-6">
                <div className="navbar-brand logo_h" style={{ width: 202 }}>
                  <img src={`${toAbsoluteUrl("/media/logos/I-DELTA-logo.svg")}`}
                       height='auto'/>
                </div>

                <div className='powered-by' style={{
                  marginTop: 30, float: "left", marginLeft: 14,
                  clear: "left"
                }}>
                  Powered by
                </div>
                <div style={{
                  marginTop: 10,
                  float: "left",
                  clear: "left",
                  display: "flex"
                }}>
                  <div className="navbar-brand logo_h" style={{ width: 202 }}>
                    <a href="https://itea3.org/" target='_blank'>
                      <img src={`${toAbsoluteUrl("/media/img/home/itea3-logo-footer.png")}`}
                           width='100%'
                           height='auto'/>
                    </a>
                  </div>
                  <div className="navbar-brand logo_h">
                    <a href="https://www.eurekanetwork.org/" target='_blank'>
                      <img src={`${toAbsoluteUrl("/media/img/home/Euraka-logo-footer.svg")}`}
                           width='100%'
                           height='auto'/>

                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <Navigation>
                  <div className="main_menu">
                    <nav className="navbar navbar-expand-lg navbar-light">
                      <div className="container box_1620" style={{ justifyContent: "flex-start" }}>
                        <nav className="nav">
                          <i
                            className="fa fa-bars"
                            aria-hidden="true"
                            onClick={e => handleToggle(e)}
                          />
                        </nav>
                        <div>
                          <ul
                            className='nav navbar-nav menu_nav ' style={{ marginLeft: 10 }}>
                            <li className="nav-item">
                              <Link to="/about" className="nav-link" style={{

                                color: "#1B2848",
                                fontFamily: "Roboto",
                                fontSize: 16,
                                fontWeight: 500

                              }}>
                                About
                              </Link>
                            </li>
                            <li className="nav-item">

                              <Link to="/consortium" className="nav-link" style={{

                                color: "#1B2848",
                                fontFamily: "Roboto",
                                fontSize: 16,
                                fontWeight: 500

                              }}>
                                Consortium
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to="/impact" className="nav-link" style={{

                                color: "#1B2848",
                                fontFamily: "Roboto",
                                fontSize: 16,
                                fontWeight: 500

                              }}>
                                Impact
                              </Link>
                            </li>
                            {/*

                                                        <li className="nav-item">
                                                            <Link to="/results" className="nav-link" style={{

                                                                color: '#1B2848',
                                                                fontFamily: 'Roboto',
                                                                fontSize: 16,
                                                                fontWeight: 500,

                                                            }}>
                                                                Results
                                                            </Link>

                                                        </li>
                                                        <li className="nav-item">
                                                            <Link to="/news" className="nav-link" style={{

                                                                color: '#1B2848',
                                                                fontFamily: 'Roboto',
                                                                fontSize: 16,
                                                                fontWeight: 500,

                                                            }}>
                                                                News
                                                            </Link>

                                                        </li>
                                                        */}
                            <li className="nav-item">
                              <Link to="/contact" className="nav-link" style={{

                                color: "#1B2848",
                                fontFamily: "Roboto",
                                fontSize: 16,
                                fontWeight: 500

                              }}>
                                Contact
                              </Link>

                            </li>

                          </ul>


                        </div>

                      </div>
                    </nav>
                  </div>
                </Navigation>
                <div className="navbar-brand logo_h"
                     style={{ float: "right", marginTop: 10, display: "flex" }}>
                  <a href="https://twitter.com/idelta2020" target='_blank'
                     style={{ width: 26, height: 26, marginRight: 20 }}>
                    <img src={`${toAbsoluteUrl("/media/img/home/twitter-icon.svg")}`}
                         width='100%'
                         height='auto'/>
                  </a>
                  <a target='_blank' style={{ width: 26, height: 26, marginRight: 20 }}>
                    <img src={`${toAbsoluteUrl("/media/img/home/linkedin-icon.svg")}`}
                         width='100%'
                         height='auto'/>
                  </a>
                  <a target='_blank' style={{ width: 26, height: 26 }}>
                    <img src={`${toAbsoluteUrl("/media/img/home/medium-icon.svg")}`}
                         width='100%'
                         height='auto'/>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-12 "

                 style={{
                   marginTop: 20
                 }}>
              <div className="col-md-6" style={{
                marginLeft: 14,
                color: "#1B2848",
                fontFamily: "Roboto",
                fontSize: 12,
                display: "flex"
              }}>
                Copyright ©2020 I-DELTA. All rights reserved.

              </div>

            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
