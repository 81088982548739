import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import logo from "../../../_metronic/layout/assets/helper-gif/datasetAdd_1.gif";


const useStyles = makeStyles(theme => ({
  typography: {
    margin: theme.spacing(2)
  }
}));


export default function Guide() {
  const classes = useStyles();

  return (

    <PopupState variant="popover" popupId="demo-popup-popover">
      {popupState => (
        <div>
          <div
            {...bindTrigger(popupState)}
            id="kt_scrolltop" className="kt-scrolltop"
            style={{
              display: "flex", justifyContent: "center",
              alignItems: "center", width: 40, height: 40, position: "absolute",
              bottom: 100, right: 20, cursor: "pointer", zIndex: 100, background: "#d5134f",
              opacity: 1,
              // transition: all 0.3s;

              borderRadius: 4
            }}>

            <i {...bindTrigger(popupState)} className="flaticon2-telegram-logo"/>

          </div>

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
          >
            <Typography style={{ boxShadow: "1px 3px 1px #d5134f", padding: 20,
              borderLeft:"4px solid #d5134f",
              borderRight:"4px solid #d5134f",
              borderBottom:"4px solid #d5134f",
              borderTop:"24px solid #d5134f",
              // backgroundColor:"#5d78ff"

            }}>
              <h2>Guide</h2>
              {/* <img src={logo} alt="loading..." width={300} height={200} style={{ border: "2px solid #d2d2d2" }}/>    */}
              <h5 style={{ marginTop: 10 }}>Dataset Upload</h5>
              <p style={{ marginTop: 10 }}>When uploading dataset, the file you will upload should not contain any files.</p>
              {/*
              <div className="kt-padding-10 text-center">
                <Button variant="outlined" color="secondary" className={classes.button} style={{marginRight:12,color:'#a60f3e', borderColor:'#a60f3e' }}>
                  Remind
                </Button>
                <Button variant="contained" color="secondary" className={classes.button} style={{marginLeft:12,backgroundColor:'#a60f3e'}}>
                  Done
                </Button>
              </div>
              */}
            </Typography>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}
