import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ErrorPage1 } from "../errors/ErrorPage1";


export default function SuccessPage() {
  return (
      <Switch>
        <Redirect from="/success" exact={true} to="/error/error-v1" />
        <Route path="/error/error-v1" component={ErrorPage1} />
      </Switch>
  );
}
