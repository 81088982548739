import React, {useMemo, useState, useEffect} from "react";
import {toAbsoluteUrl} from "../../../_metronic";
import {NavLink, Link} from "react-router-dom";
import "../../../_metronic/vendors/bootstrap/bootstrap.min.css";
import "../../../_metronic/vendors/themify-icons/themify-icons.css";
import "../../../_metronic/vendors/linericon/style.css";
import "../../../_metronic/vendors/owl-carousel/owl.theme.default.min.css";
import "../../../_metronic/vendors/owl-carousel/owl.carousel.min.css";
import "../../../_metronic/vendors/style.css";
import "../../../_metronic/_assets/sass/custom/styleAut2.css";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
    textField: {
        height: 50,
        width: 359,
        borderRadius: 2,
        borderColor: '#fff',
        backgroundColor: '#FFFFFF'
    },
    input: {
        height: 50,
        width: 359,
        borderRadius: 2,
        borderColor: '#fff',
        backgroundColor: '#FFFFFF'
    }
});

const Navigation = styled.header`



  .gray {
  }
  a {
    opacity: 1;
    transition: all 0.6s;
    font-size: 1em;
  }

  .fa-bars {
    display: none;
    font-size: 2rem;
  }
  nav {
    ul {
      display: flex;
      justify-content: space-between;
    }
    li {
      margin: 0 15px;
      justify-content: space-between;
      font-size: 1em;
    }
    a {
      font-size: 1em;
      text-decoration: none;
      .active {
      }
    }
    a.active {
    }
  }

  @media only screen and (max-width: 800px) {
    padding: 0px;
    .logo {
      padding-left: 15px;
      padding-top: 0px !important;
    }
  }
  @media only screen and (max-width: 600px) {
    height: auto;
    min-height: 50px;
    display: block;
    position: relative;
    .logo {
      width: 100%;
      display: block;
      padding-top: 20px;
      margin: 0px;
      margin-left: -5px;
      a {
        padding: 20px 0px;
      }
    }
    .fa-bars {
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    ul.collapsed {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: wrap;

      overflow: hidden;
      max-height: 0;
      -moz-transition-duration: 0.4s;
      -webkit-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      transition-duration: 0.4s;
      -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

      &.is-expanded {
        overflow: hidden;
        max-height: 500px; /* approximate max height */
        -moz-transition-duration: 0.4s;
        -webkit-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s;
        -moz-transition-timing-function: ease-in;
        -webkit-transition-timing-function: ease-in;
        -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
      }
      li {
        padding: 15px 10px;
        padding: 15px 10px;
        margin: 0px 0px;
        width: 100%;
        
      }
    }
  }
`;

export default function About() {
    const [isExpanded, setExpanded] = useState(false);
    const classes = styles();

    function handleToggle(e) {
        e.preventDefault();

        setExpanded(!isExpanded);

    }

    return (
        <>
            <div className="section anim">
                <header className="header_area">
                    <Navigation>

                        <div className="main_menu">
                            <nav className="navbar navbar-expand-lg navbar-light"
                                 style={{backgroundColor: 'white', height: 75}}>
                                <div className="container box_1620" style={{justifyContent: "flex-start"}}>
                                    <Link to="/home" className="navbar-brand logo_h" style={{width: 202}}>
                                        <img src={`${toAbsoluteUrl("/media/logos/I-DELTA-logo.svg")}`}
                                             height='auto'/>
                                    </Link>
                                    <nav className="nav">
                                        <i
                                            className="fa fa-bars"
                                            aria-hidden="true"
                                            onClick={e => handleToggle(e)}
                                        />
                                    </nav>
                                    <div className=" navbar-collapse offset" id="navbarSupportedContent"
                                         style={{backgroundColor: 'white'}}>
                                        <ul
                                            className={`collapsed ${isExpanded ? "is-expanded" : "nav navbar-nav menu_nav justify-content-end"}`}>
                                            <li className="nav-item">
                                                <Link to="/about" className="nav-link" style={{

                                                    color: '#1B2848',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 16,
                                                    fontWeight: 500,

                                                }}>
                                                    About
                                                </Link>
                                            </li>
                                            <li className="nav-item">

                                                <Link to="/consortium" className="nav-link" style={{

                                                    color: '#1B2848',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 16,
                                                    fontWeight: 500,

                                                }}>
                                                    Consortium
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/impact" className="nav-link" style={{

                                                    color: '#1B2848',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 16,
                                                    fontWeight: 500,

                                                }}>
                                                    Impact
                                                </Link>
                                            </li>

                                            {/*

                                            <li className="nav-item">
                                                <Link to="/results" className="nav-link" style={{

                                                    color: '#1B2848',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 16,
                                                    fontWeight: 500,

                                                }}>
                                                    Results
                                                </Link>

                                            </li>
                                            <li className="nav-item">
                                                <Link to="/news" className="nav-link" style={{

                                                    color: '#1B2848',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 16,
                                                    fontWeight: 500,

                                                }}>
                                                    News
                                                </Link>

                                            </li>
                                            
                                            */}
                                            <li className="nav-item">
                                                <Link to="/contact" className="nav-link" style={{

                                                    color: '#1B2848',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 16,
                                                    fontWeight: 500,

                                                }}>
                                                    Contact
                                                </Link>

                                            </li>

                                        </ul>


                                    </div>

                                </div>
                            </nav>
                        </div>
                    </Navigation>
                </header>


                <main className="side-main" style={{paddingTop: 80}}>
                    <section className="hero-banner hero-banner-about mb-30px">
                        <div className="container">
                            <div className="row">

                                <div className="col-lg-6">
                                    <div className="hero-banner__content">
                                        <h1 style={{
                                            color: '#1B2848',
                                            fontFamily: 'Roboto',
                                            fontSize: 48,
                                            fontWeight: 'bold',
                                            letterSpacing: '-0.4px',
                                            textAlign: 'left',
                                        }}>
                                            About I-DELTA


                                        </h1>
                                        <h3 style={{
                                            color: '#1B2848',
                                            fontFamily: 'Roboto',
                                            fontSize: 24,
                                            fontWeight: 500,
                                            letterSpacing: -0.2,

                                        }}>
                                            I-DELTA is a ITEA 3 project that focuses on Distributed Ledger Technologies


                                        </h3>
                                        <span style={{display: 'flex', marginTop: 44}}>

                                      <div style={{
                                          height: 50,
                                          width: 135,
                                          borderRadius: 2,
                                          backgroundColor: '#0A8AFF',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center'

                                      }}>
                                        <Link to='/contact' style={{
                                            color: '#FFFFFF',
                                            fontFamily: 'Roboto',
                                            fontSize: 14,
                                            fontWeight: 500,
                                            letterSpacing: 0,
                                            textAlign: 'center',

                                        }}>
                                           Contact Us
                                        </Link>

                                      </div>
                                    </span>

                                    </div>
                                </div>

                                <div className="col-lg-6 about-bubbles">
                                    <div className="col-lg-4 hero-banner__img">
                                        <img className='about-bubble'
                                             src={`${toAbsoluteUrl("/media/img/about/itea3-bubble.png")}`}
                                             height="262"/>
                                    </div>
                                    <div className="col-lg-4 hero-banner__img about-bubble-box"
                                         style={{marginLeft: 80, marginTop: -30}}>
                                        <img className='about-bubble'
                                             src={`${toAbsoluteUrl("/media/img/about/idelta-bubble.png")}`}
                                             height="144"/>
                                    </div>
                                    <div className="col-lg-4 hero-banner__img about-bubble-box"
                                         style={{marginLeft: 100, marginTop: 130}}>
                                        <img className='about-bubble'
                                             src={`${toAbsoluteUrl("/media/img/about/Eureka-bubble.png")}`}
                                             height="194"/>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </section>


                    <section className="section-padding--small bg-magnolia" style={{backgroundColor: "#fff"}}>
                        <div className="container">
                            <div className="row no-gutters align-items-center">
                                <div className="col-md-6">
                                    <div className="col-md-12" style={{display: 'flex'}}>

                                        <div className="col-md-6" style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                            <img className="img-responsive"
                                                 src={`${toAbsoluteUrl("/media/img/home/itea3-logo.png")}`} width='173'
                                                 height='72'/>
                                        </div>

                                        <div className="col-md-6" style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                            <img className="img-responsive"
                                                 src={`${toAbsoluteUrl("/media/img/home/eureka-logo.png")}`} width='161'
                                                 height='62'/>
                                        </div>
                                    </div>

                                    <div className="col-md-12" style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignContent: 'center',
                                        marginTop: 30
                                    }}>
                                        <div className='about-page-bubble-main'>
                                            <div className='about-page-bubble'>
                                                6
                                            </div>
                                            <div
                                                className='about-page-bubble-sub'>
                                                Countries
                                            </div>
                                        </div>
                                        <div className='about-page-bubble-main' >
                                            <div className='about-page-bubble'>
                                                19
                                            </div>
                                            <div
                                                className='about-page-bubble-sub'>
                                                Partners
                                            </div>
                                        </div>
                                        <div className='about-page-bubble-main'>
                                            <div className='about-page-bubble'>
                                                5
                                            </div>
                                            <div
                                                className='about-page-bubble-sub'>
                                                Industries
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-1 mb-1 mb-md-0">
                                </div>
                                <div className="col-md-5 mb-5 mb-md-0">
                                    <div className="about__content">
                                        <h2 style={{
                                            color: '#1B2848',
                                            fontFamily: 'Roboto',
                                            fontSize: 42,
                                            fontWeight: 500,
                                            letterSpacing: -0.35,
                                            textAlign: 'left',
                                            lineHeight: 1,
                                            display: 'inline-block'
                                        }}>Project Overview</h2>
                                        <span className='the-consortium-cons'>
                                           I-DELTA is a ITEA 3 project that focuses on Distributed Ledger Technologies (DLT), it is led by 19 consortiums from 6 contries.
                                            Distributed Ledger Technologies (DLT) undoubtedly are
                                            a cutting-edge new breed of technologies with the potential to completely transform the way our society works. DLT will foster switching from the “Internet of information" era to the “Internet of Value" era, whereby decentralised and immutable contracts define business interactions and secure exchanges of information. I-DELTA aims to create an interoperable DLT based platform enhanced by AI, integrating with existing IT systems such as ERP and IoT applications.
                                       </span>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section className="section-padding--small bg-magnolia" style={{backgroundColor: "#94CCFF 0.1"}}>
                        <div className="container">
                            <div className="row no-gutters align-items-center">
                                <div className="col-md-5 mb-5 mb-md-0">
                                    <div className="about__content">
                                        <h2 style={{
                                            color: '#1B2848',
                                            fontFamily: 'Roboto',
                                            fontSize: 42,
                                            fontWeight: 500,
                                            letterSpacing: -0.35,
                                            textAlign: 'left',
                                            lineHeight: 1,
                                            display: 'inline-block'
                                        }}>Our Vision</h2>
                                        <span className='the-consortium-cons'>
                                     I-DELTA aims to create an interoperable DLT based platform enhanced by AI,
                                            integrating with existing IT systems such as ERP and IoT applications.
                                            The latter will delight us with a growing
                                            number of IOT devices independently taking part in business transactions and triggering decisions.
                                       </span>

                                    </div>

                                    <div className="about__content" style={{marginTop: 30}}>
                                        <h2 style={{
                                            color: '#1B2848',
                                            fontFamily: 'Roboto',
                                            fontSize: 42,
                                            fontWeight: 500,
                                            letterSpacing: -0.35,
                                            textAlign: 'left',
                                            lineHeight: 1,
                                            display: 'inline-block'
                                        }}>Our Mission</h2>
                                        <span className='the-consortium-cons'>
                                   To achieve this, I-DELTA has carefully assembled a consortium of entities from different key countries.
                                            These entities will be cooperating in delivering proof-of-concept DLTs in different domains such as smart cities,
                                            smart communities,
                                            grids, and Industry 4.0 supply chains, all of which will use a common and interoperable architecture.
                                       </span>

                                    </div>
                                </div>
                                <div className="col-md-1 mb-1 mb-md-0">
                                </div>
                                <div className="col-md-6">

                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <img className="img-responsive"
                                             src={`${toAbsoluteUrl("/media/img/about/blockchain_graphic.png")}`}
                                             />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section-padding--small bg-magnolia" style={{backgroundColor: "#fff"}}>
                        <div className="container">
                            <div className="row no-gutters align-items-center">
                                <div className="about__content">
                                        <span style={{
                                            height: 147,
                                            color: '#1B2848',
                                            fontFamily: 'Roboto',
                                            fontSize: 34,
                                            fontWeight: 500,
                                            letterSpacing: -0.35,
                                            textAlign: 'left',
                                            lineHeight: 1
                                        }}>We are supported by Public Authorities</span>

                                </div>
                                <div className="col-lg-12 row" style={{display: 'flex', marginTop: 60}}>
                                    <div className="col-lg-2" style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: 40
                                    }}>
                                        <img className="img-responsive"
                                             src={`${toAbsoluteUrl("/media/img/home/itea3-logo.png")}`} width='242'
                                             height='72'/>
                                    </div>

                                    <div className="col-lg-2" style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: 40
                                    }}>
                                        <img className="img-responsive"
                                             src={`${toAbsoluteUrl("/media/img/home/eureka-logo.png")}`} width='219'
                                             height='62'/>
                                    </div>
                                    <div className="col-lg-2" style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: 40
                                    }}>
                                        <img className="img-responsive"
                                             src={`${toAbsoluteUrl("/media/img/home/canada-IRAC.png")}`} width='135'
                                             height='71'/>
                                    </div>
                                    <div className="col-lg-2" style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: 40
                                    }}>
                                        <img className="img-responsive"
                                             src={`${toAbsoluteUrl("/media/img/home/Tubitak-logo.jpg")}`} width='58'
                                             height='77'/>
                                    </div>
                                    <div className="col-lg-2" style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: 40
                                    }}>
                                        <img className="img-responsive"
                                             src={`${toAbsoluteUrl("/media/img/home/CDTI-logo.jpg")}`} width='140'
                                             height='72'/>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </section>

                    <section className="section-padding--small bg-magnolia" style={{backgroundColor: "#193B91"}}>
                        <div className="container">
                            <div className="col-md-12">
                                <div className="col-md-7 row no-gutters align-items-center">
                                    <div className="about__content">
                                        <span style={{
                                            height: 49,
                                            color: '#FFFFFF',
                                            fontFamily: 'Roboto',
                                            fontSize: 42,
                                            fontWeight: 500,
                                            letterSpacing: -0.35,
                                            textAlign: 'left',
                                            lineHeight: 1
                                        }}>Subscribe to I-DELTA updates </span>

                                        <div style={{
                                            color: '#FFFFFF',
                                            marginTop: 20,
                                            fontFamily: 'Roboto',
                                            fontWeight: 500,
                                            fontsize: 16,
                                            letterSpacing: -0.23,
                                            lineHeight: 1
                                        }}>Receive the updates of our research results and DLT innovation
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="hero-banner__content">
                                        <span style={{display: 'flex'}}>
                                      <TextField
                                          inputProps={{
                                              style: {
                                                  height: 10,
                                                  width: 320,
                                                  borderRadius: 2,
                                                  borderColor: '#fff',
                                                  fontSize: 16,
                                                  fontFamily: 'Roboto',
                                                  color: '#1B2848',
                                                  backgroundColor: '#FFFFFF', padding: 20
                                              },
                                          }}

                                          placeholder="Enter your email to receive updates"
                                      />
                                      <div style={{
                                          marginLeft: 20,
                                          height: 50,
                                          width: 135,
                                          borderRadius: 2,
                                          backgroundColor: '#0A8AFF',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center'

                                      }}>
                                        <span style={{
                                            color: '#FFFFFF',
                                            fontFamily: 'Barlow',
                                            fontSize: 16,
                                            fontWeight: 500,
                                            letterSpacing: 0,
                                            textAlign: 'center',

                                        }}>
                                           Subcribe
                                        </span>

                                      </div>
                                    </span>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </section>
                </main>

                <footer className="footer-area section-gap">
                    <div className="container" style={{color: "#fff"}}>
                        <div className="col-md-12 row" style={{marginBottom: 50}}>
                            <div className="col-md-6">
                                <div className="navbar-brand logo_h" style={{width: 202}}>
                                    <img src={`${toAbsoluteUrl("/media/logos/I-DELTA-logo.svg")}`}
                                         height='auto'/>

                                </div>

                                <div className='powered-by' style={{
                                    marginTop: 30, float: 'left', marginLeft: 14,
                                    clear: 'left'
                                }}>
                                    Powered by

                                </div>
                                <div style={{
                                    marginTop: 10,
                                    float: 'left',
                                    clear: 'left',
                                    display: 'flex'
                                }}>

                                    <div className="navbar-brand logo_h" style={{width: 202}}>
                                        <a href="https://itea3.org/" target='_blank'>
                                            <img src={`${toAbsoluteUrl("/media/img/home/itea3-logo-footer.png")}`}
                                                 width='100%'
                                                 height='auto'/>
                                        </a>
                                    </div>
                                    <div className="navbar-brand logo_h" >
                                        <a href="https://www.eurekanetwork.org/" target='_blank'>
                                            <img src={`${toAbsoluteUrl("/media/img/home/Euraka-logo-footer.svg")}`}
                                                 width='100%'
                                                 height='auto'/>

                                        </a>
                                    </div>
                                </div>

                            </div>

                            <div className="col-md-6">
                                <Navigation>

                                    <div className="main_menu">
                                        <nav className="navbar navbar-expand-lg navbar-light">
                                            <div className="container box_1620" style={{justifyContent: "flex-start"}}>
                                                <nav className="nav">
                                                    <i
                                                        className="fa fa-bars"
                                                        aria-hidden="true"
                                                        onClick={e => handleToggle(e)}
                                                    />
                                                </nav>
                                                <div>
                                                    <ul
                                                        className='nav navbar-nav menu_nav ' style={{marginLeft: 10}}>
                                                        <li className="nav-item">
                                                            <Link to="/about" className="nav-link" style={{

                                                                color: '#1B2848',
                                                                fontFamily: 'Roboto',
                                                                fontSize: 16,
                                                                fontWeight: 500,

                                                            }}>
                                                                About
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">

                                                            <Link to="/consortium" className="nav-link" style={{

                                                                color: '#1B2848',
                                                                fontFamily: 'Roboto',
                                                                fontSize: 16,
                                                                fontWeight: 500,

                                                            }}>
                                                                Consortium
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link to="/impact" className="nav-link" style={{

                                                                color: '#1B2848',
                                                                fontFamily: 'Roboto',
                                                                fontSize: 16,
                                                                fontWeight: 500,

                                                            }}>
                                                                Impact
                                                            </Link>
                                                        </li>

                                                        {/*
                                                        <li className="nav-item">
                                                            <Link to="/results" className="nav-link" style={{

                                                                color: '#1B2848',
                                                                fontFamily: 'Roboto',
                                                                fontSize: 16,
                                                                fontWeight: 500,

                                                            }}>
                                                                Results
                                                            </Link>

                                                        </li>
                                                        <li className="nav-item">
                                                            <Link to="/news" className="nav-link" style={{

                                                                color: '#1B2848',
                                                                fontFamily: 'Roboto',
                                                                fontSize: 16,
                                                                fontWeight: 500,

                                                            }}>
                                                                News
                                                            </Link>

                                                        </li>
                                                        */}
                                                        <li className="nav-item">
                                                            <Link to="/contact" className="nav-link" style={{

                                                                color: '#1B2848',
                                                                fontFamily: 'Roboto',
                                                                fontSize: 16,
                                                                fontWeight: 500,

                                                            }}>
                                                                Contact
                                                            </Link>

                                                        </li>

                                                    </ul>


                                                </div>

                                            </div>
                                        </nav>
                                    </div>
                                </Navigation>
                                <div className="navbar-brand logo_h"
                                     style={{float: 'right', marginTop: 10, display: 'flex'}}>
                                    <a href="https://twitter.com/idelta2020" target='_blank' style={{width:26, height:26,marginRight: 20}}>
                                        <img src={`${toAbsoluteUrl("/media/img/home/twitter-icon.svg")}`}
                                             width='100%'
                                             height='auto'/>
                                    </a>
                                    <a target='_blank' style={{width:26, height:26,marginRight: 20}}>
                                        <img src={`${toAbsoluteUrl("/media/img/home/linkedin-icon.svg")}`}
                                             width='100%'
                                             height='auto'/>
                                    </a>
                                    <a target='_blank' style={{width:26, height:26}}>
                                        <img src={`${toAbsoluteUrl("/media/img/home/medium-icon.svg")}`}
                                             width='100%'
                                             height='auto' />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 "

                             style={{
                                 marginTop: 20
                             }}>
                            <div className="col-md-6" style={{
                                marginLeft: 14,
                                color: '#1B2848',
                                fontFamily: 'Roboto',
                                fontSize: 12,
                                display: 'flex'
                            }}>
                                Copyright ©2020 I-DELTA. All rights reserved.

                            </div>

                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}
