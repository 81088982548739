export default {
  header: {
    self: {},
    items: [
      {
        title: "Dashboards",
        root: true,
        alignment: "left",
        page: "dashboard",
        translate: "MENU.DASHBOARD"
      },
      {
        title: "Projects",
        root: true,
        alignment: "left",
        page: "projects",
        translate: "MENU.DASHBOARD",

      },

      {
        title: "Server",
        root: true,
        alignment: "left",
        page: "server",
        translate: "MENU.DASHBOARD"
      },

      {
        title: "Deployed Models",
        root: true,
        alignment: "left",
        page: "models",
        translate: "MENU.DASHBOARD"
      },

    ]
  },
  aside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        bullet: "dot"
      },
      {
        title: "Projects",
        root: true,
        alignment: "left",
        page: "projects",
        translate: "MENU.DASHBOARD",
        bullet: "dot"
      },

      {
        title: "Server",
        root: true,
        alignment: "left",
        page: "server",
        translate: "MENU.DASHBOARD",
        bullet: "dot"
      },

      {
        title: "Deployed Models",
        root: true,
        alignment: "left",
        page: "model",
        translate: "MENU.DASHBOARD",
        bullet: "dot"
      },

      {
        title: "Error Pages",
        root: true,
        bullet: "dot",
        page: "error/error-v1",
        icon: "flaticon-danger",
        submenu: [
          {
            title: "Error Page - 1",
            page: "error/error-v1"
          },
          {
            title: "Error Page - 2",
            page: "error/error-v2"
          },
          {
            title: "Error Page - 3",
            page: "error/error-v3"
          },
          {
            title: "Error Page - 4",
            page: "error/error-v4"
          },
          {
            title: "Error Page - 5",
            page: "error/error-v5"
          },
          {
            title: "Error Page - 6",
            page: "error/error-v6"
          }
        ]
      }
    ]
  }
};
