import React, {useMemo, useState, useEffect} from "react";
import {toAbsoluteUrl} from "../../../_metronic";
import {NavLink, Link} from "react-router-dom";
import "../../../_metronic/vendors/bootstrap/bootstrap.min.css";
import "../../../_metronic/vendors/themify-icons/themify-icons.css";
import "../../../_metronic/vendors/linericon/style.css";
import "../../../_metronic/vendors/owl-carousel/owl.theme.default.min.css";
import "../../../_metronic/vendors/owl-carousel/owl.carousel.min.css";
import "../../../_metronic/vendors/style.css";
import "../../../_metronic/_assets/sass/custom/styleAut2.css";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import {compose, withProps, withHandlers} from "recompose";

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Polygon,
    Marker, KmlLayer,
} from "react-google-maps";

const {MarkerClusterer} = require("react-google-maps/lib/components/addons/MarkerClusterer");


const styles = theme => ({
    textField: {
        height: 50,
        width: 359,
        borderRadius: 2,
        borderColor: '#fff',
        backgroundColor: '#FFFFFF'
    },
    input: {
        height: 50,
        width: 359,
        borderRadius: 2,
        borderColor: '#fff',
        backgroundColor: '#FFFFFF'
    }
});
const mapStyle = {
    styles: [{
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [{"color": "#444444"}]
    }, {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [{"visibility": "on"}, {"color": "#919fb3"}]
    }, {
        "featureType": "administrative.neighborhood",
        "elementType": "labels.text.fill",
        "stylers": [{"visibility": "on"}, {"color": "#919fb3"}]
    }, {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [{"color": "#f2f2f2"}]
    }, {
        "featureType": "landscape.natural",
        "elementType": "geometry.fill",
        "stylers": [{"visibility": "on"}, {"color": "#f5f5f2"}]
    }, {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [{"visibility": "off"}]
    }, {
        "featureType": "poi.business",
        "elementType": "geometry.fill",
        "stylers": [{"visibility": "on"}]
    }, {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [{"visibility": "on"}, {"color": "#f5f5f2"}]
    }, {
        "featureType": "road",
        "elementType": "all",
        "stylers": [{"saturation": -100}, {"lightness": 45}]
    }, {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [{"visibility": "simplified"}]
    }, {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [{"visibility": "on"}, {"color": "#d6e4e7"}]
    }, {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [{"color": "#ffffff"}, {"visibility": "on"}]
    }, {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [{"visibility": "off"}]
    }, {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [{"visibility": "off"}]
    }, {
        "featureType": "transit.line",
        "elementType": "geometry.fill",
        "stylers": [{"visibility": "on"}, {"hue": "#0083ff"}]
    }, {
        "featureType": "transit.line",
        "elementType": "labels.text.fill",
        "stylers": [{"visibility": "on"}, {"color": "#3599d4"}]
    }, {
        "featureType": "transit.line",
        "elementType": "labels.text.stroke",
        "stylers": [{"color": "#d7f3fb"}, {"visibility": "on"}]
    }, {
        "featureType": "water",
        "elementType": "all",
        "stylers": [{"color": "#c9ecee"}, {"visibility": "on"}]
    }, {
        "featureType": "water",
        "elementType": "labels",
        "stylers": [{"visibility": "on"}, {"color": "#c9ecee"}]
    }],
    disableDefaultUI: true,
    mapTypeControl: false,
    zoomControl: false,

};
const Navigation = styled.header`



  .gray {
  }
  a {
    opacity: 1;
    transition: all 0.6s;
    font-size: 1em;
  }

  .fa-bars {
    display: none;
    font-size: 2rem;
  }
  nav {
    ul {
      display: flex;
      justify-content: space-between;
    }
    li {
      margin: 0 15px;
      justify-content: space-between;
      font-size: 1em;
    }
    a {
      font-size: 1em;
      text-decoration: none;
      .active {
      }
    }
    a.active {
    }
  }

  @media only screen and (max-width: 800px) {
    padding: 0px;
    .logo {
      padding-left: 15px;
      padding-top: 0px !important;
    }
  }
  @media only screen and (max-width: 600px) {
    height: auto;
    min-height: 50px;
    display: block;
    position: relative;
    .logo {
      width: 100%;
      display: block;
      padding-top: 20px;
      margin: 0px;
      margin-left: -5px;
      a {
        padding: 20px 0px;
      }
    }
    .fa-bars {
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    ul.collapsed {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: wrap;

      overflow: hidden;
      max-height: 0;
      -moz-transition-duration: 0.4s;
      -webkit-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      transition-duration: 0.4s;
      -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

      &.is-expanded {
        overflow: hidden;
        max-height: 500px; /* approximate max height */
        -moz-transition-duration: 0.4s;
        -webkit-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s;
        -moz-transition-timing-function: ease-in;
        -webkit-transition-timing-function: ease-in;
        -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
      }
      li {
        padding: 15px 10px;
        padding: 15px 10px;
        margin: 0px 0px;
        width: 100%;
        
      }
    }
  }
`;

const coords = [
    {lat: 29.047487,lng: 41.023164},
    {lat: 29.0459633,lng: 41.0212904},
    {lat: 29.0449333,lng: 41.0167573},
    {lat: 29.0393543,lng: 41.0106695},
    {lat: 29.032917,lng: 41.0049697},
    {lat: 29.0226173,lng: 41.0061356},
    {lat: 29.0078545,lng: 41.0039334},
    {lat: 29.0201283,lng: 40.9765933},
    {lat: 29.0319729,lng: 40.9657708},
    {lat: 29.0784073,lng: 40.9536501},
    {lat: 29.0944576,lng: 40.9493068},
    {lat: 29.0975475,lng: 40.9514461},
    {lat: 29.1052294,lng: 40.9647986},
    {lat: 29.097338,lng: 40.978242},
    {lat: 29.0931273,lng: 40.9835914},
    {lat: 29.0858746,lng: 40.987738},
    {lat: 29.056509,lng: 40.998902},
    {lat: 29.061456,lng: 41.008443},
    {lat: 29.0617561,lng: 41.0104752},
    {lat: 29.0595245,lng: 41.0126772},
    {lat: 29.052014,lng: 41.018198},
    {lat: 29.047487,lng: 41.023164}];

const reversedCoords = coords.map( ll => {
    return { lat: ll.lng, lng: ll.lat }
});


const MyMapComponent = compose(
    withProps({
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyBsEK7x-MvMeanzduA0hCMOIRQoccIhYkA&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{height: `100%`}}/>,
        containerElement: <div style={{height: `500px`}}/>,
        mapElement: <div style={{height: `100%`}}/>
    }),
    withHandlers({
        onMarkerClustererClick: () => (markerClusterer) => {
            const clickedMarkers = markerClusterer.getMarkers()
            console.log(`Current clicked markers length: ${clickedMarkers.length}`)
            console.log(clickedMarkers)
        },
    }),
    withScriptjs,
    withGoogleMap
)(props => (
    <GoogleMap defaultZoom={2.8} defaultCenter={{lat: 48.4557, lng: -45.535949}}
               defaultOptions={{
                   styles: mapStyle.styles,
                   mapTypeControl: false,
                   panControl: false,
                  // zoomControl: false,
                   //scaleControl: false,
                   streetViewControl: false,
                  // scrollwheel: false,
                   fullscreenControl: false
               }}
    >
        <Polygon
            path='https://raw.githubusercontent.com/isellsoap/deutschlandGeoJSON/master/1_deutschland/1_sehr_hoch.geo.json'
            //key={1}
            options={{
                fillColor: "#000",
                fillOpacity: 0.4,
                strokeColor: "#000",
                strokeOpacity: 1,
                strokeWeight: 1
            }} />

        <MarkerClusterer
            onClick={props.onMarkerClustererClick}
            averageCenter
            enableRetinaIcons
            gridSize={20}
            styles={[
                {
                    url: "/media/img/consortium/marker.svg",
                    height: 53,
                    lineHeight: 53,
                    width: 53,
                },

            ]}
        >
            {props.markers.map(marker => (
                <Marker
                    icon={{
                        url: "/media/img/consortium/marker.svg",
                    }}

                    key={marker.photo_id}
                    position={{lat: marker.latitude, lng: marker.longitude}}
                />
            ))}
        </MarkerClusterer>
    </GoogleMap>
));


let partnerData = [
    {
        'name': 'Mavennet Systems Inc.',
        'country': 'Canada',
        'siteDomain':'https://www.mavennet.com',
        'site':'www.mavennet.com',
        'description':'Mavennet is bridging the gap between the enterprise and blockchain. As a leading technology developer, our focus is on making it easy for businesses to adopt blockchain across a variety of verticals, by offering end-to-end solutions. We accompany our clients in the path towards innovation and digital transformation, from strategy and product development to the delivery of projects and technical support. Mavennet’s expertise extends to services and products in industries such as financial services, government, telecom, defense and supply chain, with a specific focus on helping to create and bring to market complete solutions that have the power to radically change the industries in which they operate.',
        'media': `${toAbsoluteUrl("/media/img/consortium/mavennet.png")}`
    }, {
        'name': 'Ryerson University',
        'country': 'Canada',
        'siteDomain':'https://www.ryerson.ca',
        'site':'www.ryerson.ca',
        'description':'The Cybersecurity Research Lab (CRL) is an academic research lab at Ted Rogers School of Management at Ryerson University. We conduct cutting-edge information security research, train the next generation of cybersecurity experts, and are spearheading a crucial and ongoing dialogue with the Information and Computer Technology (ICT) industry in Canada. The CRL is recognized for its strong partnerships and linkages to industry. Its mission is to help organizations, large and small, to find innovative and cost effective cyber risk mitigation strategy and solutions. The lab’s research expertise is in Cryptography, Blockchain Technology, Quantum-resistant solutions, Machine Learning and its applications in cybersecurity, Enterprise Security Architecture, and Security of Internet of Things (IoT).',

        'media': `${toAbsoluteUrl("/media/img/consortium/ryerson_logo.svg")}`
    }, {
        'name': 'EXPECT-IT, s. r. o.',
        'country': 'Czech Republic',
        'siteDomain':'http://www.expect-it.cz/',
        'site':'www.expect-it.cz',
        'description':'Company description',
        'media': `${toAbsoluteUrl("/media/img/consortium/expect.png")}`
    }, {
        'name': 'KUBEČKA & PROKOP, advokátní kancelář s. r. o.',
        'country': 'Czech Republic',
        'siteDomain':'https://www.kp-partners.cz/',
        'site':'www.kp-partners.cz',
        'description':'Company description',

        'media': `${toAbsoluteUrl("/media/img/consortium/kp-partners.png")}`
    }, {
        'name': 'Selfcon Systems, s.r.o',
        'country': 'Czech Republic',
        'siteDomain':'',
        'site':'',
        'description':'Company description',
        'media': `${toAbsoluteUrl("/media/img/consortium/selfcon.png")}`
    }, {
        'name': 'IOTIQ GmbH',
        'country': 'Germany',
        'siteDomain':'https://www.iotiq.de/',
        'site':'www.iotiq.de',
        'description':'The IOTIQ GmbH was founded in Leipzig in 2017. The three pillars of IOTIQ are IT research and development projects in the framework of ITEA (Europe-wide) and ZIM (Germany), product marketing (Mobile Device Management and IOT platform) as well as customized software-development. The company supports its clients in the consultation, design and development of individual software products and IT services. The services include consulting, process planning, requirements engineering and software development with modern technologies and methods such as web applications, IOT protocols, JAVA / Javascript, mobile technologies and much more. The strength of IOTIQ is the high degree of flexibility and individualization of its own products as well as the high quality and speed of order development. As part of Europe-wide ITEA projects, IOTIQ employees learn the latest IT technologies and implement them in their own projects. In addition, IOTIQ GmbH emphasizes the great importance of a good training of its developers, all of whom have studied computer science for at least four years. The IOTIQ GmbH is a multilingual and international society that carries out projects worldwide.',

        'media': `${toAbsoluteUrl("/media/img/consortium/IOTIQ.png")}`
    }, {
        'name': 'BEIA Consult International',
        'country': 'Romania',
        'siteDomain':'https://www.beiaro.eu/',
        'site':'www.beiaro.eu',
        'description':'Company description',
        'media': `${toAbsoluteUrl("/media/img/consortium/beia.png")}`
    },  {
        'name': 'Sotec Consulting',
        'country': 'Spain',
        'siteDomain':'http://www.sotec-consulting.com/',
        'site':'www.sotec-consulting.com',
        'description':'Company description',
        'media': `${toAbsoluteUrl("/media/img/consortium/sotec.png")}`
    }, {
        'name': 'University of Madrid Carlos III',
        'country': 'Spain',
        'siteDomain':'https://www.uc3m.es/Home',
        'site':'www.uc3m.es',
        'description':'The mission of Universidad Carlos III de Madrid is to contribute to the improvement of society by offering quality education and carrying out advanced research in accordance with demanding international criteria. The University aspires to excellence in all its endeavors with the objective of becoming one of the best universities in Europe. Within the public service framework of higher education, the University strives to promote the development of all those individuals who form part of the University community. The values of merit, capacity, efficiency, transparency, fairness, equality and respect for the environment will guide all of its activities.',
        'media': `${toAbsoluteUrl("/media/img/consortium/uc3m.png")}`
    }, {
        'name': 'Vector ICT',
        'country': 'Spain',
        'siteDomain':'https://www.vectoritcgroup.com/',
        'site':'www.vectoritcgroup.com',
        'description':'Vector ITC Group is a technological consulting firm that accompanies its clients from the beginning of their technological and digital transformation.',
        'media': `${toAbsoluteUrl("/media/img/consortium/vectorict.png")}`
    }, {
        'name': 'ARGEDOR Information Technologies Ltd.',
        'country': 'Turkey',
        'siteDomain':'http://www.argedor.com/',
        'site':'www.argedor.com',
        'description':'ARGEDOR helps companies and end users to access, process, research and adapt information available worldwide with innovative information processing solutions. It conducts national and international projects in parallel with technological developments and performs success-oriented R&D activities.',
        'media': `${toAbsoluteUrl("/media/img/consortium/argedor.jpg")}`
    }, {
        'name': 'Dakik Yazilim Teknolojileri',
        'country': 'Turkey',
        'siteDomain':'https://www.dakikyazilim.com/',
        'site':'www.dakikyazilim.com',
        'description':'Company description',
        'media': `${toAbsoluteUrl("/media/img/consortium/dakik.png")}`
    }, {
        'name': 'Entek Elektrik Üretimi A.Ş.',
        'country': 'Turkey',
        'siteDomain':'https://www.entekelektrik.com.tr/',
        'site':'www.entekelektrik.com.tr',
        'description':'Company description',
        'media': `${toAbsoluteUrl("/media/img/consortium/entek.jpg")}`
    }, {
        'name': 'ERSTE Software Limited.',
        'country': 'Turkey',
        'siteDomain':'https://www.ersteyazilim.com/',
        'site':'www.ersteyazilim.com',
        'description':'Company description',
        'media': `${toAbsoluteUrl("/media/img/consortium/erste.png")}`
    }, {
        'name': 'KoçSistem Information Communications Services',
        'country': 'Turkey',
        'siteDomain':'https://www.kocsistem.com.tr/',
        'site':'www.kocsistem.com.tr',
        'description':'As a pioneering organization of the IT sector in Turkey, KoçSistem holds on to the principle of providing trainings beyond the expectations of its customers through special technological solutions engineered according to specific requirements in compliance with the “end-to-end” service concept.',
        'media': `${toAbsoluteUrl("/media/img/consortium/koc.jpg")}`
    }, {
        'name': 'T2 Software',
        'country': 'Turkey',
        'siteDomain':'http://www.t2.com.tr/',
        'site':'www.t2.com.tr',
        'description':'T2 is working in DLT domain for 3 years and accomplished several successful implementation across the world. We would like to extend our domain base by bringing interoperability, integration to IoT and experience use cases from different countries. T2 will prepare a Hyperledger Fabric infrastructure and also make it interoperable across the other provided DLTs. T2 is one of the rare companies in the consortium which already has a customer base related to DLT, including national banks and big enterprises. T2 would like to disseminate results starting with our customer base.',
        'media': `${toAbsoluteUrl("/media/img/consortium/t2.jpg")}`
    }, {
        'name': 'TMOB BILISIM',
        'country': 'Turkey',
        'siteDomain':'https://thinksmobility.com/',
        'site':'thinksmobility.com',
        'description':'Company description',
        'media': `${toAbsoluteUrl("/media/img/consortium/tmob.png")}`
    },
]

let data= {
         "count": 2,
        "photos": [{
            "photo_id": 1,
            "latitude": 60.091324,
            "longitude": -106.123026,
        },{
            "photo_id": 1,
            "latitude": 60.091324,
            "longitude": -106.123026,
        },{
            "photo_id": 2,
            "latitude": 50.036822,
            "longitude": 14.671197,
        },{
            "photo_id": 2,
            "latitude": 50.036822,
            "longitude": 14.671197,
        },{
            "photo_id": 2,
            "latitude": 50.036822,
            "longitude": 14.671197,
        },{
            "photo_id": 3,
            "latitude": 51.729505,
            "longitude": 9.364843,
        },{
            "photo_id": 4,
            "latitude": 44.657092,
            "longitude": 26.892004,
        },{
            "photo_id": 5,
            "latitude": 38.825192,
            "longitude": -3.081301,
        },{
            "photo_id": 5,
            "latitude": 38.825192,
            "longitude": -3.081301,
        },{
            "photo_id": 5,
            "latitude": 38.825192,
            "longitude": -3.081301,
        },{
            "photo_id": 6,
            "latitude": 39.698073,
            "longitude": 32.421977,
        },{
            "photo_id": 6,
            "latitude": 39.698073,
            "longitude": 32.421977,
        },{
            "photo_id": 6,
            "latitude": 39.698073,
            "longitude": 32.421977,
        },{
            "photo_id": 6,
            "latitude": 39.698073,
            "longitude": 32.421977,
        },{
            "photo_id": 6,
            "latitude": 39.698073,
            "longitude": 32.421977,
        },{
            "photo_id": 6,
            "latitude": 39.698073,
            "longitude": 32.421977,
        },{
            "photo_id": 6,
            "latitude": 39.698073,
            "longitude": 32.421977,
        },


        ]}


export default function Consortium() {
    const [isExpanded, setExpanded] = useState(false);
    const [selected, setSelected] = useState(false);
    const [markers, setMarkers] = useState(false);
    const [detail, setDetail] = useState(false);
    const classes = styles();

    function handleToggle(e) {
        e.preventDefault();

        setExpanded(!isExpanded);

    }

    const handleChange = (event) => {
        setSelected(event.target.value);
    };

    useEffect(() => {
        const url = [
            `https://gist.githubusercontent.com`,
            `/farrrr/dfda7dd7fccfec5474d3`,
            `/raw/758852bbc1979f6c4522ab4e92d1c92cba8fb0dc/data.json`
        ].join("")

        fetch(url)
            .then(res => res.json())
            .then(data => {
                setMarkers(data.photos)
            });


    }, []);
    var options = {
        sizeAxis: { minValue: 0, maxValue: 100 },
        region: 'world', // Western Europe
        colorAxis: {colors: ['#e7711c', '#4374e0']} // orange to blue
    };


    var columns = [        {
        'type': 'string',
        'label' : 'Country'
    },
        {
            'type' : 'number',
            'label' : 'Number'
        }];

    return (
        <>
            <div className="section anim">
                <header className="header_area">
                    <Navigation>

                        <div className="main_menu">
                            <nav className="navbar navbar-expand-lg navbar-light"
                                 style={{backgroundColor: 'white', height: 75}}>
                                <div className="container box_1620" style={{justifyContent: "flex-start"}}>
                                    <Link to="/home" className="navbar-brand logo_h" style={{width: 202}}>
                                        <img src={`${toAbsoluteUrl("/media/logos/I-DELTA-logo.svg")}`}
                                             height='auto'/>
                                    </Link>
                                    <nav className="nav">
                                        <i
                                            className="fa fa-bars"
                                            aria-hidden="true"
                                            onClick={e => handleToggle(e)}
                                        />
                                    </nav>
                                    <div className=" navbar-collapse offset" id="navbarSupportedContent"
                                         style={{backgroundColor: 'white'}}>
                                        <ul
                                            className={`collapsed ${isExpanded ? "is-expanded" : "nav navbar-nav menu_nav justify-content-end"}`}>
                                            <li className="nav-item">
                                                <Link to="/about" className="nav-link" style={{

                                                    color: '#1B2848',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 16,
                                                    fontWeight: 500,

                                                }}>
                                                    About
                                                </Link>
                                            </li>
                                            <li className="nav-item">

                                                <Link to="/consortium" className="nav-link" style={{

                                                    color: '#1B2848',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 16,
                                                    fontWeight: 500,

                                                }}>
                                                    Consortium
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/impact" className="nav-link" style={{

                                                    color: '#1B2848',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 16,
                                                    fontWeight: 500,

                                                }}>
                                                    Impact
                                                </Link>
                                            </li>
                                            
                                            {/*
                                            <li className="nav-item">
                                                <Link to="/results" className="nav-link" style={{

                                                    color: '#1B2848',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 16,
                                                    fontWeight: 500,

                                                }}>
                                                    Results
                                                </Link>

                                            </li>
                                            <li className="nav-item">
                                                <Link to="/news" className="nav-link" style={{

                                                    color: '#1B2848',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 16,
                                                    fontWeight: 500,

                                                }}>
                                                    News
                                                </Link>

                                            </li>
                                            */}
                                            <li className="nav-item">
                                                <Link to="/contact" className="nav-link" style={{

                                                    color: '#1B2848',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 16,
                                                    fontWeight: 500,

                                                }}>
                                                    Contact
                                                </Link>

                                            </li>

                                        </ul>


                                    </div>

                                </div>
                            </nav>
                        </div>
                    </Navigation>
                </header>


                <main className="side-main" style={{paddingTop: 80}}>
                    <section className="hero-banner hero-banner-consortium ">
                        <div className="container">

                            <div style={{
                                color: '#FFFFFF',
                                fontFamily: 'Roboto',
                                fontSize: 48,
                                fontWeight: 'bold',
                                letterSpacing: '-0.16px',
                                textAlign: 'center',
                                lineHeight: 1.5
                            }}>
                                Our Consortiums

                            </div>
                            <div className="col-lg-12" style={{marginTop: 50}}>
                                <div className="col-lg-3" style={{
                                    height: '500px',
                                    width: '100%',
                                    backgroundColor: '#FFFFFF',
                                    padding: 0

                                }}>
                                    <div style={{padding: 20}}>

                                        <select
                                            style={{
                                                height: 47,
                                                width: '100%',
                                                padding: 10,
                                                color: '#787878',
                                                fontFamily: 'Roboto',
                                                fontSize: 16
                                            }}

                                            onChange={handleChange}
                                        >
                                            <option value='All'> All Countries</option>
                                            <option value='Canada'> Canada</option>
                                            <option value='Germany'> Germany</option>
                                            <option value='Romania'> Romania</option>
                                            <option value='Spain'> Spain</option>
                                            <option value='Turkey'> Turkey</option>
                                        </select>


                                    </div>
                                    <div style={{height: 410, overflow: 'auto', maxHeight: '100%'}}>
                                        {detail?  <div
                                                style={{
                                                    border: '1px solid #D0D0D0',
                                                    padding: 20
                                                }}>
                                            <div style={{cursor:'pointer', color:'#9B9B9B',fontSize: 14}} onClick={() => {
                                                setDetail(false);
                                            }}>
                                                {'< '}Back
                                            </div>
                                            <div style={{display: 'flex', width: '100%', cursor:'pointer', marginTop: 20,}}  >
                                                <div style={{flex: 1,}}>
                                                    <img src={detail.media}
                                                         width='100%' height='auto'/>
                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'flex-end',
                                                    textAlign: 'right',
                                                    flex: 1,

                                                    color: '#1B2848',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 16,

                                                }}>{detail.country}
                                                </div>
                                            </div>
                                            <div style={{
                                                marginTop: 20,
                                                color: '#1B2848',
                                                fontFamily: 'Roboto',
                                                fontSize: 16,
                                                fontWeight: 500,
                                            }}>{detail.name}
                                            </div> <a target='_blank' href={detail.siteDomain} style={{
                                                marginTop: 20,
                                                color: '#0A8AFF',
                                                fontFamily: 'Roboto',
                                                fontSize: 16,
                                                fontWeight: 500,
                                            }}>{detail.site}
                                            </a><div style={{
                                                marginTop: 20,
                                                color: '#1B2848',
                                                fontFamily: 'Roboto',
                                                fontSize: 16,
                                                fontWeight: 500,
                                            }}>{detail.description}
                                            </div>
                                        </div> :
                                            partnerData.map((item, index) => {
                                                if (selected === item.country) {
                                                    return <div
                                                        style={{
                                                            border: '1px solid #D0D0D0',
                                                            padding: 20
                                                        }}>
                                                        <div style={{display: 'flex', width: '100%' , cursor:'pointer'}}   onClick={() => {
                                                            setDetail(item);
                                                        }}>
                                                            <div style={{flex: 1,}}>
                                                                <img src={item.media}
                                                                     width='100%' height='auto'/>
                                                            </div>
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-end',
                                                                alignItems: 'flex-end',
                                                                textAlign: 'right',
                                                                flex: 1,

                                                                color: '#1B2848',
                                                                fontFamily: 'Roboto',
                                                                fontSize: 16,

                                                            }}>{item.country}
                                                            </div>
                                                        </div>
                                                        <div   onClick={() => {
                                                            setDetail(item);
                                                        }} style={{
                                                            marginTop: 20,
                                                            color: '#1B2848',
                                                            fontFamily: 'Roboto',
                                                            fontSize: 16,
                                                            fontWeight: 500,
                                                             cursor:'pointer'
                                                        }}>{item.name}
                                                        </div>
                                                    </div>
                                                } else if (selected === false || selected === 'All') {
                                                    return <div
                                                        style={{
                                                            border: '1px solid #D0D0D0',
                                                            padding: 20
                                                        }}>

                                                        <div>
                                                            <div style={{display: 'flex', width: '100%', cursor:'pointer'}}  onClick={() => {
                                                                setDetail(item);
                                                            }}>
                                                                <div style={{flex: 1,}}>
                                                                    <img src={item.media}
                                                                         width='100%' height='auto'/>
                                                                </div>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'flex-end',
                                                                    alignItems: 'flex-end',
                                                                    textAlign: 'right',
                                                                    flex: 1,

                                                                    color: '#1B2848',
                                                                    fontFamily: 'Roboto',
                                                                    fontSize: 16,

                                                                }}>{item.country}
                                                                </div>
                                                            </div>
                                                            <div   onClick={() => {
                                                                setDetail(item);
                                                            }} style={{
                                                                marginTop: 20,
                                                                color: '#1B2848',
                                                                fontFamily: 'Roboto',
                                                                fontSize: 16,
                                                                fontWeight: 500,
                                                                 cursor:'pointer'
                                                            }}>{item.name}
                                                            </div>
                                                        </div>


                                                    </div>
                                                }
                                            })
                                        }


                                    </div>
                                </div>
                                <div className="col-lg-9" style={{
                                    height: '100%',
                                    width: '100%',
                                    backgroundColor: 'white',
                                    padding: 0

                                }}>

                                    <MyMapComponent key="map" markers={data.photos}/>

                                </div>
                            </div>

                        </div>
                    </section>


                </main>

                <footer className="footer-area section-gap">
                    <div className="container" style={{color: "#fff"}}>
                        <div className="col-md-12 row" style={{marginBottom: 50}}>
                            <div className="col-md-6">
                                <div className="navbar-brand logo_h" style={{width: 202}}>
                                    <img src={`${toAbsoluteUrl("/media/logos/I-DELTA-logo.svg")}`}
                                         height='auto'/>

                                </div>

                                <div className='powered-by' style={{
                                    marginTop: 30, float: 'left', marginLeft: 14,
                                    clear: 'left'
                                }}>
                                    Powered by

                                </div>
                                <div style={{
                                    marginTop: 10,
                                    float: 'left',
                                    clear: 'left',
                                    display: 'flex'
                                }}>

                                    <div className="navbar-brand logo_h" style={{width: 202}}>
                                        <a href="https://itea3.org/" target='_blank'>
                                            <img src={`${toAbsoluteUrl("/media/img/home/itea3-logo-footer.png")}`}
                                                 width='100%'
                                                 height='auto'/>
                                        </a>
                                    </div>
                                    <div className="navbar-brand logo_h" >
                                        <a href="https://www.eurekanetwork.org/" target='_blank'>
                                            <img src={`${toAbsoluteUrl("/media/img/home/Euraka-logo-footer.svg")}`}
                                                 width='100%'
                                                 height='auto'/>

                                        </a>
                                    </div>

                                </div>

                            </div>

                            <div className="col-md-6">
                                <Navigation>

                                    <div className="main_menu">
                                        <nav className="navbar navbar-expand-lg navbar-light">
                                            <div className="container box_1620" style={{justifyContent: "flex-start"}}>
                                                <nav className="nav">
                                                    <i
                                                        className="fa fa-bars"
                                                        aria-hidden="true"
                                                        onClick={e => handleToggle(e)}
                                                    />
                                                </nav>
                                                <div>
                                                    <ul
                                                        className='nav navbar-nav menu_nav ' style={{marginLeft: 10}}>
                                                        <li className="nav-item">
                                                            <Link to="/about" className="nav-link" style={{

                                                                color: '#1B2848',
                                                                fontFamily: 'Roboto',
                                                                fontSize: 16,
                                                                fontWeight: 500,

                                                            }}>
                                                                About
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">

                                                            <Link to="/consortium" className="nav-link" style={{

                                                                color: '#1B2848',
                                                                fontFamily: 'Roboto',
                                                                fontSize: 16,
                                                                fontWeight: 500,

                                                            }}>
                                                                Consortium
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link to="/impact" className="nav-link" style={{

                                                                color: '#1B2848',
                                                                fontFamily: 'Roboto',
                                                                fontSize: 16,
                                                                fontWeight: 500,

                                                            }}>
                                                                Impact
                                                            </Link>
                                                        </li>
                                                        {/*
                                                        <li className="nav-item">
                                                            <Link to="/results" className="nav-link" style={{

                                                                color: '#1B2848',
                                                                fontFamily: 'Roboto',
                                                                fontSize: 16,
                                                                fontWeight: 500,

                                                            }}>
                                                                Results
                                                            </Link>

                                                        </li>
                                                        <li className="nav-item">
                                                            <Link to="/news" className="nav-link" style={{

                                                                color: '#1B2848',
                                                                fontFamily: 'Roboto',
                                                                fontSize: 16,
                                                                fontWeight: 500,

                                                            }}>
                                                                News
                                                            </Link>

                                                        </li>
                                                        */}
                                                        <li className="nav-item">
                                                            <Link to="/contact" className="nav-link" style={{

                                                                color: '#1B2848',
                                                                fontFamily: 'Roboto',
                                                                fontSize: 16,
                                                                fontWeight: 500,

                                                            }}>
                                                                Contact
                                                            </Link>

                                                        </li>

                                                    </ul>


                                                </div>

                                            </div>
                                        </nav>
                                    </div>
                                </Navigation>
                                <div className="navbar-brand logo_h"
                                     style={{float: 'right', marginTop: 10, display: 'flex'}}>
                                    <a href="https://twitter.com/idelta2020" target='_blank' style={{width:26, height:26,marginRight: 20}}>
                                        <img src={`${toAbsoluteUrl("/media/img/home/twitter-icon.svg")}`}
                                             width='100%'
                                             height='auto'/>
                                    </a>
                                    <a target='_blank' style={{width:26, height:26,marginRight: 20}}>
                                        <img src={`${toAbsoluteUrl("/media/img/home/linkedin-icon.svg")}`}
                                             width='100%'
                                             height='auto'/>
                                    </a>
                                    <a target='_blank' style={{width:26, height:26}}>
                                        <img src={`${toAbsoluteUrl("/media/img/home/medium-icon.svg")}`}
                                             width='100%'
                                             height='auto' />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 "

                             style={{
                                 marginTop: 20
                             }}>
                            <div className="col-md-6" style={{
                                marginLeft: 14,
                                color: '#1B2848',
                                fontFamily: 'Roboto',
                                fontSize: 12,
                                display: 'flex'
                            }}>
                                Copyright ©2020 I-DELTA. All rights reserved.

                            </div>

                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}
