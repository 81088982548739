/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";

class UserProfile extends React.Component {
  render() {
    const { user, showHi, showAvatar, showBadge,lang } = this.props;
    console.log(user)

    return (
      <Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" alignRight style={{float:'right'}}
      >
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-profile"
        >
            {showHi && (
              <span className="kt-header__topbar-welcome kt-hidden-mobile">
                Hi,
              </span>
            )}

            {showHi && (
              <span className="kt-header__topbar-username kt-hidden-mobile">
                {user!==""?user.name:""}
              </span>
            )}

            {showAvatar && <img alt="Pic" src={toAbsoluteUrl("/media/users/300_21.jpg")} />}

            {showBadge && (
              <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold kt-hidden-">
                {/* TODO: Should get from currentUser */}
                {user!==""?user.name[0]:""}
              </span>
            )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          <div
            className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`
            }}
          >
            <div className="kt-user-card__avatar">
              <img alt="Pic" className="kt-hidden" src={user.pic} />
              <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
                   {user!==""?user.name[0]:""}
              </span>
            </div>
            <div className="kt-user-card__name">{user.name}</div>
            {/*<div className="kt-user-card__badge">*/}
              {/*<span className="btn btn-success btn-sm btn-bold btn-font-md">*/}
                {/*23 messages*/}
              {/*</span>*/}
            {/*</div>*/}
          </div>
          <div className="kt-notification">

            <Link className="kt-notification__item" to="/profile">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-calendar-3 kt-font-success" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  Profile
                </div>
                <div className="kt-notification__item-time">
                  Account settings and more
                </div>
              </div>
            </Link>
            {/*<Link className="kt-notification__item" to="/roles">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-avatar kt-font-success" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  Team
                </div>
                <div className="kt-notification__item-time">
                  Account settings and more
                </div>
              </div>
            </Link>
            */}
            <Link className="kt-notification__item" to="/credit-card">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-mail kt-font-warning" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  Credit Card
                </div>
                <div className="kt-notification__item-time">
                  Inbox and tasks
                </div>
              </div>
            </Link>
            <Link className="kt-notification__item" to="/history">

              <div className="kt-notification__item-icon">
                <i className="flaticon-price-tag kt-font-success" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  Transaction History
                </div>
                <div className="kt-notification__item-time">
                  Inbox and tasks
                </div>
              </div>
            </Link>
            {/*<a className="kt-notification__item">*/}
              {/*<div className="kt-notification__item-icon">*/}
                {/*<i className="flaticon2-rocket-1 kt-font-danger" />*/}
              {/*</div>*/}
              {/*<div className="kt-notification__item-details">*/}
                {/*<div className="kt-notification__item-title kt-font-bold">*/}
                  {/*My Activities*/}
                {/*</div>*/}
                {/*<div className="kt-notification__item-time">*/}
                  {/*Logs and notifications*/}
                {/*</div>*/}
              {/*</div>*/}
            {/*</a>*/}
            {/*<a className="kt-notification__item">*/}
              {/*<div className="kt-notification__item-icon">*/}
                {/*<i className="flaticon2-hourglass kt-font-brand" />*/}
              {/*</div>*/}
              {/*<div className="kt-notification__item-details">*/}
                {/*<div className="kt-notification__item-title kt-font-bold">*/}
                  {/*My Tasks*/}
                {/*</div>*/}
                {/*<div className="kt-notification__item-time">*/}
                  {/*latest tasks and projects*/}
                {/*</div>*/}
              {/*</div>*/}
            {/*</a>*/}
            <div className="kt-notification__custom">
              <Link
                to="/logout"
                className="btn btn-label-brand btn-sm btn-bold"
              >
                Sign Out
              </Link>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({ auth: { user,lang } }) => ({
  user,
  lang
});

export default connect(mapStateToProps)(UserProfile);
